/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// @import './app/features/auth/amplify-theme.scss';
@import '~normalize.css';
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Open+Sans:400,700|Ubuntu|Ubuntu+Mono&display=swap');

html,
body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  background-color: #e5ecec;

  .mat-mdc-tooltip  {
    white-space: pre-line;
  }
}

p {
  height: auto;
}

span {
  color: inherit;
}

a.as-btn:link,
a.as-btn:visited,
a.as-btn:active {
  color: rgba($color: #000000, $alpha: 0.87);
}

button {
  text-transform: uppercase;
  font-family: 'Ubuntu', sans-serif;

  &.no-style {
    text-transform: none;
    background: none;
    color: #00bcd4;
    border: none;
    padding: 0;
    outline: none;
    font: inherit;
    cursor: pointer;

    &:hover:not(:disabled) {
      color: #0097a7;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.54);
      cursor: default !important;
    }
  }
}

.error {
  color: #c62828;

  a {
    font-weight: 700;

    &:link {
      color: #c62828;
    }

    &:hover {
      color: #ff1744;
    }
  }
}

// forecast app
app-forecast .mapboxgl-map .mapboxgl-control-container {
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    margin-bottom: 64px;

    @media screen and (min-width: 600px) {
      margin-bottom: 90px;
    }
  }

  .mapboxgl-ctrl-bottom-left {
    @media screen and (min-width: 900px) {
      margin-bottom: 0;
    }
  }

  .mapboxgl-ctrl-bottom-right {
    @media screen and (min-width: 1000px) {
      margin-bottom: 0;
    }
  }
}

// maps
.boxdraw {
  background: rgba($color: #006064, $alpha: 0.1);
  border: 2px solid #00acc1;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

$pane-width: 280px;

app-maps {
  .main-map {
    &.layer-pane {
      .mapboxgl-ctrl-bottom-left,
      .mapboxgl-ctrl-top-left {
        left: $pane-width;
      }
    }

    &.is-animating {
      .mapboxgl-ctrl-top-right {
        display: none;
      }
    }

    &.elements-pane {
      .mapboxgl-ctrl-top-right {
        right: calc(#{$pane-width} + 10px);
      }
    }

    .mapboxgl-map .mapboxgl-control-container {
      .mapboxgl-ctrl-top-left {
        margin-left: 20px;
      }

      .mapboxgl-ctrl-bottom-left,
      .mapboxgl-ctrl-top-left {
        transition: left 200ms cubic-bezier(0, 0, 0.2, 1);
      }

      .mapboxgl-ctrl-top-right {
        transition: right 200ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
}

// Globally scoped styles
snack-bar-container {
  &.error,
  &.old {
    background-color: #b71c1c;
  }

  &.late {
    background-color: #ffc107;
    color: rgba($color: #000, $alpha: 0.87);
    .mat-mdc-snack-bar-action {
      color: rgba($color: #000, $alpha: 0.87);
    }
  }
}

// maps general
.mapboxgl-ctrl.mapboxgl-ctrl-group {
  border-radius: 2px;
  button.mapbox-gl-draw_ctrl-draw-btn.active:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.1);
  }
}


.cdk-overlay-container {
  .cdk-overlay-backdrop.hide-backdrop {
    opacity: 0;
  }

  .cdk-overlay-dark-backdrop {
    backdrop-filter: blur(1px);
  }

  .no-padding {
    .mat-mdc-dialog-container {
      padding: 0;
    }
  }

  .drop-down {
    .mat-mdc-dialog-container {
      padding: 0;
      display: contents !important;
      @include mat.elevation(3);
    }
  }

  .details-dialog {
    .mat-mdc-dialog-container, .mat-mdc-dialog-container .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .mat-mdc-tab-labels {
      background-color: #fff;
    }

    .mat-mdc-dialog-container {
      padding: 8px;

      @media screen and (min-width: 600px) {
        padding: 16px;
      }
    }

    .mat-mdc-dialog-title {
      font-family: 'Ubuntu', sans-serif;
      padding-left: 0px;
    }

    .mat-mdc-dialog-content {
      margin: 0 -8px;
      padding: 0 8px 8px;

      @media screen and (min-width: 600px) {
        margin: 0 -16px;
        padding: 0 16px 8px;
      }

      .mat-mdc-list-base {
        margin: 0 -8px;

        @media screen and (min-width: 600px) {
          margin: 0 -16px;
        }
      }
      .mat-mdc-input-element {
        font-family: 'Ubuntu Mono', monospace;
        font-size: 16px;
      }
    }
  }
}

.mat-mdc-option .mdc-list-item__primary-text {
  text-wrap: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.mat-sort-header-container {
  line-height: normal;
}

td[mat-cell].data {
  font-size: 16px !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
  font-size: 12px;
  color: #0000008a;
  font-weight: lighter;
  padding: 0 0 0 16px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

// placed here in order to avoid using the deprecated ::ng-deep
#gauge-status-viewport {
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

[id^='chart-'] {
  .c3-regions {
    .region-watch {
      fill: #f0e442;
    }

    .region-warning {
      fill: #d55c00;
    }

    .region-warn {
      fill: #d55c00;
    }

    .region-greenway {
      fill: #009e74;
    }
  }

  .c3-grid {
    .c3-xgrid-line {
      pointer-events: none;

      &.now-label text {
        transform: rotate(-90deg) translateY(16px);
      }
      line {
        stroke: #999;
      }

      text {
        font-weight: bold;
        font-size: 12px;
        fill: #757575;
      }
    }
  }

  .c3-line {
    stroke-width: 2px;
  }

  .c3-axis {
    &.c3-axis-y,
    &.c3-axis-y2 {
      .c3-axis-y-label,
      .c3-axis-y2-label {
        font-weight: bold;
        font-size: 12px;
        fill: #757575;
      }

      .tick {
        font-family: 'Ubuntu Mono', 'Courier New', Courier, monospace;
      }
    }
  }

  .c3-chart-lines {
    [class*='-lines-obs-'] {
      opacity: 0 !important;
    }

    [class*='-circles-obs-'] circle {
      opacity: 1 !important;
    }

    .c3-target-cumulative,
    .c3-target-iCumulative {
      .c3-circles > circle.c3-circle {
        display: none;

        &._expanded_ {
          display: inherit;
        }
      }
    }
  }
}

.mdc-button {
  text-transform: uppercase !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  text-transform: uppercase !important;
  padding-bottom: 4px;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: #fff !important;
}

// Needed for Forecast->left nav->Open bottom menu->Forecast History Button.
.forecast-history {
  display: contents;
  .mat-mdc-unelevated-button:not(:disabled) {
    color: #fff !important;
  }
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
  min-width: max-content;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  .mat-mdc-select-value {
    margin-left: -12px;
  }
  .mdc-floating-label--float-above {
    margin-left: -12px;
  }
}

.flex-container {
  position: fixed;
  z-index: 2;
  box-shadow: 0 3px 3px -2px #0003,0 3px 4px #00000024,0 1px 8px #0000001f;

  .mat-mdc-icon-button svg {
    width: var(--mdc-icon-button-icon-size, 32px);
    height: var(--mdc-icon-button-icon-size, 32px);
    margin-top: -4px;
  }

  .mat-mdc-icon-button img {
    width: var(--mdc-icon-button-icon-size, 36px);
    height: var(--mdc-icon-button-icon-size, 36px);
  }
}

.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.cdk-drag-preview {
  border: solid 1px #999 !important;
  background-color: #FFFFFF !important;
  opacity: 0.8;
}

mat-card {
  .mat-mdc-progress-bar {
    width: 100%;
    margin: -8px 0 4px;
  }

  &.no-pad {
    padding: 8px 0 0;
  }

  .pad {
    padding: 8px;
  }

  .layers-meta-container {
    padding-top: 0;
    padding-right: 0;

    .meta-text {
      color: #757575;
      font-size: 12px;

      .layer-count {
        font-weight: bold;
      }

      .duration-info {
        span {
          font-style: italic;
        }
      }
    }
  }

  .mat-mdc-list-base[dense].mat-mdc-list {
    .mdc-list-item--with-leading-avatar .mdc-list-item__start {
      margin-left: 0;
      margin-right: 0;
      padding-left: 10px;
      padding-top: 6px;
    }

    .layer-container {
      display: flex;
      flex-direction: row;
      margin-top: -16px;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

      // Secondary text is the time period below the layer name.
      .mdc-list-item__secondary-text {
        color: rgba(0,0,0,0.87);
        margin-top: -42px;
        font-size: 11px !important;
      }

      &:active {
        cursor: grabbing;
      }

      .action {
        display: none;
        margin-top: -26px;
      }

      &:hover {
        .layer {
          display: none;
        }

        .action {
          margin-top: 2px;
          display: block;
        }
      }

      // Style when map layer visibility is toggled off.
      &.is-invisible {
        button.show-hide-toggle, h4 {
          opacity: 0.54;
        }

        // The layer text when the layer is hidden;
        .mat-line, .mat-line.primary {
          color: #0000008a;
          display: contents;
        }
        // The time text line below the layer name when layer is hidden.
        .mdc-list-item__secondary-text {
          color: rgba(0,0,0,0.54);
        }

        .secondary-actions > * {
          &:disabled, &.disabled {
            color: rgba(0,0,0,0.54);
            &:hover {
              color: rgba(0, 0, 0, 0.54);
            }
          }
        }
      }

      &.placeholder {
        box-shadow: inset 0px 0px 4px rgba(0,0,0,0.24);
        height: 48px;
      }

      .mat-mdc-list-item-icon {
        padding: 0;
        &.mat-mdc-icon-button {
          line-height: inherit;
          &.hide {
            display: none;
          }
        }
      }

      // Style the layer text when the layer is visible.
      .mat-line {
        font-size: 11px;
        display: contents;
        &.primary {
          font-size: 13px;
          color: rgba(0,0,0,0.87);
          padding-bottom: 3px;
        }
      }

      // Settings/Delete/Drag layer buttons. Hidden by default.
      .secondary-actions {
        margin-top: -42px;
        display: none;
        & > * {
          color: rgba(0,0,0,0.54);
          &:not([disabled]), &:not(.disabled) {
            &:hover {
              color: rgba(0,0,0,0.87);
            }
          }
        }
      }
    }

    // Settings/Delete/Drag layer buttons displayed on hover.
    &:not(.cdk-drop-list-dragging) .layer-container:hover {
      background: #EEE;
      .secondary-actions:not([hidden])  {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        position: absolute;
        right: 12px;
      }
    }
  }

  .add-layer-wrapper {
    button.add-layer {
      width: 100%;
      background-color: #FFD54F;
      color: rgba(0, 0, 0, 0.54) !important;

      &:hover:enabled {
        color: rgba(0, 0, 0, 0.87);
      }

      &:disabled {
        background-color: #FFECB3;
        color: rgba(0, 0, 0, 0.35);
      }
    }
  }

  .layer-error {
    .mat-icon {
      color: #C62828;
    }
  }

  // style the base map layer section at the bottom of the left side-bar.
  .baselayer-wrapper {
    position: relative;
    margin: 8px;

    .info-container {
      padding: 8px 0;

      .title {
        font-size: 12px;
        font-weight: bold;
        color: #757575;
        padding: 8px 0;
      }

      .layer-item {
        width: 100%;
        color: #424242;

        .name {
          text-align: left;
          margin-left: 8px;
        }

        &:disabled {
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }
  }
}

.mdc-list-item {
  display: flex;
}

.list-container {
  .mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
    display: none;
  }

  .mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
    font-size: 14px;
  }

  .mdc-list-item__secondary-text {
    display: inline-flex;
    font-size: 12px;
  }
}

.what-if-list {
  .mat-mdc-list-option .mdc-radio {
    display: none;
  }
}

.actions-dialog {
  padding: 16px;
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  flex: 1 1 100% !important;
}

.downloads-tab-control {
  .mat-mdc-tab-links {
    width: 25%;
  }
}

.rules-tab-control {
  .mat-mdc-tab-header {
    width: 50%;
  }
}

.profile-elements {
  .mat-mdc-list-base{
    margin: 0 -16px !important;
  }

  .mdc-list-item.mdc-list-item--with-one-line {
    height: var(--mdc-list-list-item-one-line-container-height);
    border: 1px solid rgba(0,0,0,.15);
  }

  .mat-icon {
    vertical-align: bottom;
  }
}

// Map selection list popup.
.feature-selection {
  .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
    align-self: end !important;
    margin-right: -8px;
  }
}

// Custom style for right map bar, so entire text displays on pull-downs.
.element {
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    margin-right: -15px !important;
  }
}



