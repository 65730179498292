@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$vai-primary: mat.define-palette(mat.$cyan-palette);
$vai-accent:  mat.define-palette(mat.$amber-palette, 500, A100, A700);

// The warn palette is optional (defaults to red).
$vai-warn:    mat.define-palette(mat.$red-palette);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Open Sans, sans-serif',
  $headline-5: mat.define-typography-level(32px, 48px, 700),
  $body-2: mat.define-typography-level(16px, 24px, 500)
);

@include mat.all-component-typographies($custom-typography);

// Create the theme object (a Sass map containing all of the palettes).
$vai-theme: mat.define-light-theme($vai-primary, $vai-accent, $vai-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vai-theme);

a {
    text-decoration: none;

    &:link, &:visited, &:active {
        color: #008ba3;
    }
    &:hover {
        color: #00ACC1;
    }
}

::selection {
    background: #FFC107;
}
::-moz-selection {
    background: #FFC107;
}
